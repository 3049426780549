export const generateUrlString = (string) => {
  let tag = string;
  if(typeof string !== "string")
    return "Nope"

  tag = tag.replace(/\s/g, '-');

  tag = tag.toLowerCase()

  // Regex away anything "unsafe", but ignore the join string!
  tag = tag.replace(/((?!([a-z0-9])).)/gi, function(match) {
    if (match === '-')
      return match;

    return ''
  })

  return tag.trim()
}
