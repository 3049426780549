import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-mdx'
import { MDXProvider } from '@mdx-js/tag'
import Typography from '@material-ui/core/Typography'
import ContentLayout from '../components/ContentLayout'
import { generateUrlString } from './stringGenerate'


const components = {
  h1: props => <Typography variant="h3" component="h2" gutterBottom id={generateUrlString(props.children)} {...props} />,
  h2: props => <Typography variant="h4" component="h3" gutterBottom id={generateUrlString(props.children)} {...props} />,
  h3: props => <Typography variant="h5" component="h4" gutterBottom id={generateUrlString(props.children)} {...props} />,
  h4: props => <Typography variant="h6" component="h5" gutterBottom id={generateUrlString(props.children)} {...props} />,
  p: props => <Typography paragraph {...props} />,
  // Check for internal links
  // eslint-disable-next-line
  a: props => <a {...props.href.indexOf("#") === 0 ? {} : { target: "_blank" }} {...props}  />,
  ol: props => <Typography><ol {...props} /></ Typography>,
  ul: props => <Typography><ul {...props} /></ Typography>,
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx

    return (
      <ContentLayout
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        keywords={post.frontmatter.keywords}

        image={post.frontmatter.thumbnail.childImageSharp.fluid}
        url={post.fields.slug}
      >

        <Typography variant="h2" component="h1" gutterBottom>
          {post.frontmatter.title}
        </Typography>

        <MDXProvider components={components}>
          <MDXRenderer>{post.code.body}</MDXRenderer>
        </MDXProvider>
      </ContentLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      code {
        body
      }
      frontmatter {
        title
        description
        keywords
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
